















































































import {Component, Ref, Vue} from 'vue-property-decorator'

import Ckeditor from '~/components/ckeditor.vue'
import {createRequest} from '~/utils/network-request'
import {BlogPermission} from '~/utils/permissions'
import ContentEditor from '~/components/app/content-editor.vue'
import generateHTMLFromBlocks from '~/utils/json-blocks-to-html'
import { blogFooterScopeOptions } from '~/components/data-class/data-class'
import {BlogFooter as BlogFooterModel} from "@afterschool.dev/as-data-admin";

@Component({
    components: {
        Ckeditor,
        ContentEditor
    },
    metaInfo() {
        return {
            title: 'Blog Footer'
        }
    }
})
export default class BlogFooter extends Vue {
    static permission = BlogPermission.EditBlogFooter

    @Ref() contentEditor: ContentEditor

    blogFooterId: string

    title: string = ""
    scheduleStart: Date | null = null
    scheduleEnd: Date | null = null
    enabled: boolean = false
    content_json: any = null

    // Filters
    selectedType: "" | "global" | "author" | "blog" = "global"
    selectedInstructorMemberId = ""
    selectedBlogIdArray = [] as string[]
    blogFooterScopeOptions = blogFooterScopeOptions

    async created() {
        if (this.$route.params['id']) {
            this.blogFooterId = this.$route.params['id']
            const res = await createRequest(`/blog/footer/${this.blogFooterId}`, 'get').send()

            const footer: BlogFooterModel = res.data.footer

            if (footer.start) this.scheduleStart = new Date(footer.start)
            if (footer.end) this.scheduleEnd = new Date(footer.end)
            this.enabled = footer.enabled
            this.content_json = footer.content_json
            this.title = footer.title

            if (!!footer.member_id) {
                this.selectedInstructorMemberId = footer.member_id
                this.selectedType = "author"
            }
            else if (footer.blog_id) {
                this.selectedBlogIdArray = footer.blog_id
                this.selectedType = "blog"
            }
        }
        else {
            this.content_json = {}
        }
    }

    async save() {
        if (this.title === "") {
            this.$message.error('Please enter a title for the blog footer!')
            return
        }

        if (this.selectedType === "blog" && this.selectedBlogIdArray.length < 1) {
            this.$message.error('Please select a blog for scope!')
            return
        }

        if (this.selectedType === "author" && !this.selectedInstructorMemberId) {
            this.$message.error('Please select an instructor for scope!')
            return
        }

        const update: Omit<BlogFooterModel, "_id"> = {
            title: this.title,
            enabled: this.enabled,
            blog_id: this.selectedType === "blog" ? this.selectedBlogIdArray : null,
            member_id: this.selectedType === "author" ? this.selectedInstructorMemberId : null,
            content_json: this.content_json,
            start: this.scheduleStart,
            end: this.scheduleEnd
        }

        if (this.blogFooterId) {
            // Update
            try {
                await createRequest(`/blog/footer/${this.blogFooterId}`, 'patch', {}, update).send()
                this.$message.success('Updated')
            }
            catch (e) {
                this.$message.error('Update failed')
            }
        }
        else {
            // Create
            try {
                const res = await createRequest('/blog/footer', 'post', {}, update).send()
                this.blogFooterId = res.data["id"];
                await this.$router.push(`/blog-footer/edit/${this.blogFooterId}`)
                this.$message.success('Created')
            }
            catch (e) {
                this.$message.error('Creation failed')
            }
        }
    }
}

